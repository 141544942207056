/**
 * Function to create an object with a label and value.
 *
 * @param value - the value in the object
 * @param label - optional label in the object, defaults to the value if not provided
 * @returns object with label and value
 */
export const createLabelValueObject = (value: string, label?: string) => {
  return {
    label: label ?? value,
    value: value,
  };
};

/**
 * Function to create a label-value list from an array of strings.
 *
 * @param items - list of string items to generate label-value objects from
 * @param getLabel - optional function to get the label from each item (defaults to the value if not provided)
 * @returns - list of objects with label and value properties
 */
export const createLabelValueList = (
    items: string[],
    getLabel?: (item: string) => string
) => {
  return items.map((item) => createLabelValueObject(item, getLabel?.(item)));
};
