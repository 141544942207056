const CONSTANTS = Object.freeze({
    GENERIC_ERROR_MESSAGE: 'There was a problem with your request. Please try again later or contact Technical support.',
    EDIT_PAGE_NO_CHANGE_ERROR_MESSAGE: 'No changes are made to the existing data.',
    ENVIRONMENT_VARIABLES: {
        ICRS_UI_STAGE: '{{ICRS_UI_STAGE}}',
        AWS_REGION: '{{AWS_REGION}}',
        IDENTITY_POOL_ID: '{{IDENTITY_POOL_ID}}',
        USER_POOL_ID: '{{USER_POOL_ID}}',
        USER_POOL_APP_CLIENT_ID: '{{USER_POOL_APP_CLIENT_ID}}',
        USER_POOL_SUB_DOMAIN: '{{USER_POOL_SUB_DOMAIN}}',
        REDIRECT_URI_SIGNIN: '{{REDIRECT_URI_SIGNIN}}',
        REDIRECT_URI_SIGNOUT: '{{REDIRECT_URI_SIGNOUT}}',
        ICRS_API_GATEWAY_ARN: '{{ICRS_API_GATEWAY_ARN}}'
    },
    ACCESS_ROLES: {
        ADMIN: "admin",
        TAX_USER: "taxUser",
        ACCOUNTING_USER: "accountingUser",
        VIEWER: "viewer"
    },
    COGNITO_ROLE_ATTRIBUTE: 'custom:Role',
    GET_REGISTRATION_RECORDS_COLUMNS: {
        REGISTRATION_NUMBER: "registrationNumber",
        APPROVAL_WORKFLOW_STAGE: "approvalWorkflowStage",
        ATP_CALCULATION_FLAG: "atpCalculationFlag",
        TAX_PRODUCT_CATEGORY_NAME: "taxProductCategoryName",
        TAX_PRODUCT_NAME: "taxProductName",
        ACCOUNTING_OWNER: "accountingOwner",
        TAX_OWNER: "taxOwner",
        LAST_UPDATED_DATE_TIME: "lastUpdatedDateTime",
    },
    CREATE_RECORD_PAGE_ERROR_DISPLAY_TIME: 8000,
    FIELDS_INFO: {
        EXPORT_OF_SERVICES: 'Are the Export of Services Conditions Met',
        COMMENTS: 'Enter any notes or comments you may have regarding this particular registration',
        PROVIDER_COMPANY_CODE: 'Please select company code of the entity providing goods or services',
        RECIPIENT_COMPANY_CODE: 'Please select company code of the entity receiving goods or services',
        BEAT: 'Transaction subject to Base Erosion and Anti-abuse Tax (BEAT)',
        WORKBOOK_NAME: 'Workbook unique identifier'
    },
    APPROVAL_WORKFLOW_STAGES: {
        DRAFT: 'Draft',
        IN_ACCOUNTING_APPROVAL: 'In Accounting Approval',
        IN_TAX_APPROVAL: 'In Tax Approval',
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
        CANCELLED: 'Cancelled'
    }
});

export default CONSTANTS;